import axios from "axios";
import clsx from "clsx";
import { Field, FieldProps, Formik, FormikHelpers } from "formik";
import { lazy, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import Yup from "@/base/utils/yup-extended";

import { countries } from "@/base/utils/countries";
import {
  fathomEventsAvailable,
  fathomTrack,
} from "@/base/utils/fathom-analytics";
import { gtmEvent } from "@/base/utils/google-tag-manager";
import { professions } from "@/base/utils/professions";
import Button from "@/components/elements/Button";
import Input from "@/components/elements/Input";
import Select from "@/components/elements/Select";
import LazyIntersectionObserver from "../LazyIntersectionObserver";
import * as styles from "./styles.module.scss";
import { FormValues, NewsletterAPIResponse } from "./types";

const newsletterSchema = Yup.object().shape({
  first_name: Yup.string().required("Field is required."),
  last_name: Yup.string().required("Field is required."),
  email: Yup.string()
    .email("Invalid email address.")
    .required("Field is required.")
    .emailExists("Email already exists."),
  profession: Yup.string().required("Field is required."),
  country: Yup.string().required("Field is required."),
});

export function NewsletterForm() {
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [errorMsg, setErrorMsg] = useState<boolean | string>(false);

  const recaptchaRef = useRef(null);

  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  const GoogleRecaptcha = lazy(
    () => import("@/components/elements/GoogleRecaptcha/GoogleRecaptcha")
  );

  const LazyGoogleRecaptchaComponent = () => {
    return (
      <div className={styles.recaptchaContainer}>
        <GoogleRecaptcha recaptchaRef={recaptchaRef} />
      </div>
    );
  };

  const initialValues: FormValues = {
    email: "",
    first_name: "",
    last_name: "",
    profession: "",
    country: "",
  };

  const handleSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    const body = {
      Email: values.email,
      Name: `${values.first_name} ${values.last_name}`,
      CustomFields: [
        `Profession=${values.profession}`,
        `Country=${values.country}`,
      ],
    };

    const recaptchaValue =
      recaptchaRef.current && recaptchaRef.current.getValue();

    if (!recaptchaValue) {
      const event = {
        eventName: fathomEventsAvailable["sign-up-newsletter-form"],
        props: {
          location: window.location.pathname,
          result: "Click ended with captcha error",
        },
      };
      fathomTrack(event);
      gtmEvent(event);
      setErrorMsg("reCAPTCHA verification is required.");
      return;
    }

    try {
      const response: NewsletterAPIResponse = await axios.post(
        "https://api.moosend.com/v3/subscribers/8bf6c9eb-6d7a-4991-8660-4345f52231d7/subscribe.json?apikey=1157f7c1-3d3a-4414-96d1-c930f4729d0c",
        body,
        {
          headers,
        }
      );

      if (response.status === 200) {
        const allEmails = JSON.parse(
          localStorage.getItem("signedEmails") || "[]"
        );
        const signUpEvent = {
          eventName: fathomEventsAvailable["sign-up-newsletter-form"],
          props: {
            location: window.location.pathname,
            result: "Click ended with a successful subscribe",
          },
        };

        // code 0 is success
        switch (response.data.Code) {
          case 0:
            setIsSubscribed(true);
            setErrorMsg(false);
            localStorage.setItem(
              "signedEmails",
              JSON.stringify([...allEmails, response.data.Context.Email])
            );
            fathomTrack(signUpEvent);
            gtmEvent(signUpEvent);
            break;
          case 429:
            setErrorMsg("Too many requests. Please try again later.");
            break;
          default:
            setIsSubscribed(false);
            setErrorMsg("Something went wrong. Please try again later.");
        }
      }
      setSubmitting(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  return (
    <div className={styles.newsletterContainer}>
      <img
        src="/svgs/newsletterForm/background.svg"
        alt="background"
        className={styles.backgroundImage}
        loading="lazy"
      />
      {isSubscribed ? (
        <div
          className={clsx(
            {
              [styles.newsletterSubscribed]: isSubscribed,
            },
            styles.newsletterForm
          )}
        >
          <div className={styles.checkmark}>
            <img src="/svgs/checkmark.svg" alt="checkmark" />
          </div>
          <p className={styles.subscribed}>
            Thank you. You have successfully subscribed to our regular
            e-newsletter.
          </p>
        </div>
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={newsletterSchema}
          onSubmit={handleSubmit}
        >
          {({ handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit} className={styles.newsletterForm}>
              <div className={styles.nameContainer}>
                <div>
                  <Field type="text" name="first_name">
                    {({ field, meta, form: { isSubmitting } }: FieldProps) => (
                      <Input
                        type="text"
                        placeholder="First name"
                        labelText="First name"
                        error={meta.touched && meta.error && meta.error}
                        disabled={isSubmitting}
                        {...field}
                      />
                    )}
                  </Field>
                </div>
                <div>
                  <Field name="last_name">
                    {({ field, meta, form: { isSubmitting } }: FieldProps) => (
                      <Input
                        type="text"
                        placeholder="Last name"
                        labelText="Last name"
                        error={meta.touched && meta.error && meta.error}
                        disabled={isSubmitting}
                        {...field}
                      />
                    )}
                  </Field>
                </div>
              </div>
              <Field name="email">
                {({ field, meta, form: { isSubmitting } }: FieldProps) => (
                  <Input
                    type="email"
                    placeholder="Email"
                    labelText="Email address"
                    error={meta.touched && meta.error && meta.error}
                    disabled={isSubmitting}
                    {...field}
                  />
                )}
              </Field>
              <Field name="profession">
                {({ field, meta }: FieldProps) => (
                  <Select
                    labelText="Profession"
                    error={meta.touched && meta.error}
                    items={[
                      {
                        value: "",
                        label: "Select profession",
                      },
                      ...professions,
                    ]}
                    {...field}
                  />
                )}
              </Field>
              <Field name="country">
                {({ field, meta }: FieldProps) => (
                  <Select
                    labelText="Country / region"
                    error={meta.touched && meta.error}
                    items={[
                      {
                        value: "",
                        label: "Select country / region",
                      },
                      ...Object.values(countries)
                        .sort((a, b) => a.localeCompare(b))
                        .map((country) => ({
                          value: country,
                          label: country,
                        })),
                    ]}
                    {...field}
                  />
                )}
              </Field>
              <Button
                variant="primary"
                type="submit"
                disabled={isSubmitting || isSubscribed}
              >
                Subscribe
              </Button>
              {errorMsg && <p className={styles.errorMessage}>{errorMsg}</p>}
              <p className={styles.unsubscribe}>
                You will be able to unsubscribe from the newsletter at any time
              </p>

              <LazyIntersectionObserver
                Component={LazyGoogleRecaptchaComponent}
                inView={inView}
                ref={ref}
              />
            </form>
          )}
        </Formik>
      )}
    </div>
  );
}
